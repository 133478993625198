import Vue from 'vue'
import axios from "axios";
import qs from "qs";
import { Toast,Indicator } from 'mint-ui';
//

axios.defaults.headers.post["Content-Type"] =
	"application/x-www-form-urlencoded;charset=UTF-8";
let isLoginNone = false;
axios.interceptors.response.use(
	function(response) {
		//token失效
		if(response.data.code == 402) {
			Toast({
			  message: '您尚未登录,登录体验更多',
			  position: 'middle',
			  duration: 1000
			});
			Indicator.close()
			localStorage.setItem('token','')
		}else if(response.data.code == 401) {
			Toast({
			  message: response.data.msg,
			  position: 'middle',
			  duration: 1000
			});
		}else if(response.data.code == 500) {
			Toast({
			  message: response.data.msg,
			  position: 'middle',
			  duration: 1000
			});
			localStorage.setItem('token','')
		}
		return response.data;
	},
	function(error) {
		return Promise.reject(error);
	}
);
/********************设置一个请求拦截器*************************/
axios.interceptors.request.use(
	function(config) {
		if(config.data) {
			config.data.token = localStorage.getItem('token');
			config.data.userId = localStorage.getItem('userId');
		}
		var contentType = config.headers["Content-Type"];
		if(config.method === "post" && contentType != "multipart/form-data") {
			config.data = qs.stringify(config.data);
		}
		return config;
	},
	function(error) {
		return Promise.reject(error);
	}
);

//上传头像
export const uploadPic = param => {
	return axios.post("/book-rest/api/uploadPic", param, {
		headers: {
			"Content-Type": "multipart/form-data"
		}
	});
};
//获取图文详情
export const getResourceContents = param => {
	return axios.post("/book-rest/api/getResourceContents", param, {});
};
//用户页面微信获取code
export const preLoginUrl = param => {
	return axios.post("/book-rest/api/preLoginUrl", param, {});
};
//用户登录
export const login = param => {
	return axios.post("/book-rest/api/login", param, {});
};
//消息列表
export const sysMsgList = param => {
	return axios.post("/book-rest/api/sysMsgList", param, {});
};
//授权微信
export const wxUserAuth = param => {
	return axios.post("/book-rest/api/wxUserAuth1", param, {});
};
//是否关注过二维码
export const isSubscribe = param => {
	return axios.post("/book-rest/api/isSubscribe", param, {});
};


//关注二维码信息
export const qrcodeUrl = param => {
	return axios.post("/book-rest/api/qrcodeUrl", param, {});
};
// 轮播图
export const banners = param => {
	return axios.post("/book-rest/api/banners", param, {});
};
//获取查询标签
export const tags = param => {
	return axios.post("/book-rest/api/tags", param, {});
};
/* 获取查询标签(新) */
export const tagsNew = param => {
	return axios.post("/book-rest/api/tagsNew", param, {});
};
/* end */
//获取首页配套资源
export const bookTypes = param => {
	return axios.post("/book-rest/api/bookTypes", param, {});
};
//教师备课资源的列表
export const preparations = param => {
	return axios.post("/book-rest/api/preparations", param, {});
};
// 教师备课资源的列表(新)
export const preparationsNew = param => {
	return axios.post("/book-rest/api/preparationsNew", param, {});
};
/* 教师备课资源的列表(新) */
export const getVersionListByApiPreparations = param => {
	return axios.post("/book-rest/api/getVersionListByApiPreparations", param, {});
};
/* end */
//教师备课资源详情
export const preDetail = param => {
	return axios.post("/book-rest/api/preDetail", param, {});
};
//章节下列表
export const preparationContents = param => {
	return axios.post("/book-rest/api/preparationContents", param, {});
};
//章节下资源列表
export const listResource = param => {
	return axios.post("/book-rest/api/listResource", param, {});
};

/* 搜索 */
// 搜索图书列表
export const getBooksNewByTitel = param => {
	return axios.post("/book-rest/api/getBooksNewByTitel", param, {});
};
export const getPreparationsByTitel = param => {
	return axios.post("/book-rest/api/getPreparationsByTitel", param, {});
};
/* end */

//试卷列表
export const testPaper = param => {
	return axios.post("/book-rest/api/testPaper", param, {});
};

//图书列表
export const getBooks = param => {
	return axios.post("/book-rest/api/getBooks", param, {});
};
//图书列表 (新)
export const getBooksNew = param => {
	return axios.post("/book-rest/api/getBooksNew", param, {});
};
// 版本列表 (加)
export const getVersionListByApiBooks = param => {
	return axios.post("/book-rest/api/getVersionListByApiBooks", param, {});
};
//加入书架
export const addBookShelf = param => {
	return axios.post("/book-rest/api/addBookShelf", param, {});
};
//移除书架
export const removeBook = param => {
	return axios.post("/book-rest/api/removeBook", param, {});
};


//图书详情
export const bookDetail = param => {
	return axios.post("/book-rest/api/bookDetail", param, {});
};
//图书目录
export const bookContents = param => {
	return axios.post("/book-rest/api/bookContents", param, {});
};
//推荐书籍
export const recBooks = param => {
	return axios.post("/book-rest/api/recBooks", param, {});
};

/*资源下载中心*/
export const downloadResource = param => {
	return axios.post("/book-rest/api/downloadResource", param, {});
};

/*个人中心*/
//用户信息
export const userInfo = param => {
	return axios.post("/book-rest/api/userInfo", param, {});
};
//修改个人信息
export const modifyInfo = param => {
	return axios.post("/book-rest/api/modifyInfo", param, {});
};

//收货地址
export const userAddrs = param => {
	return axios.post("/book-rest/api/userAddrs", param, {});
};
//删除收货地址
export const delUserAddr = param => {
	return axios.post("/book-rest/api/delUserAddr", param, {});
};
//编辑收货地址
export const modifyUserAddr = param => {
	return axios.post("/book-rest/api/modifyUserAddr", param, {});
};
//添加地址
export const addUserAddr = param => {
	return axios.post("/book-rest/api/addUserAddr", param, {});
};
//获取省
export const provinces = param => {
	return axios.post("/book-rest/api/provinces", param, {});
};
//获取市县
export const cityOrRegions = param => {
	return axios.post("/book-rest/api/cityOrRegions", param, {});
};




//我的书架
export const myBookShelf = param => {
	return axios.post("/book-rest/api/myBookShelf", param, {});
};

//活动介绍
export const activity = param => {
	return axios.post("/book-rest/api/activity", param, {});
};
//报名活动
export const joinActivity = param => {
	return axios.post("/book-rest/api/joinActivity", param, {});
};
//报名列表
export const applyRecords = param => {
	return axios.post("/book-rest/api/applyRecords", param, {});
};
//活动报名的详情
export const applyDetail = param => {
	return axios.post("/book-rest/api/applyDetail", param, {});
};
//关于我们
export const about = param => {
	return axios.post("/book-rest/api/about", param, {});
};
// 用户协议
export const userXY = param => {
	return axios.post("/book-rest/api/userXY", param, {});
};
// 隐私政策
export const yszc = param => {
	return axios.post("/book-rest/api/yszc", param, {});
};